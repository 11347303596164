<template lang="html">
  <div class="container pt-3">
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item">
          <router-link :to="{ name: 'Index' }">Панель</router-link>
        </li>
        <li class="breadcrumb-item active" aria-current="page">
          Логи
        </li>
      </ol>
    </nav>
    <div class="d-flex mb-3">
      <h3>Логи</h3>
    </div>

    <div class="table-responsive">
      <table class="table table-bordered table-hover">
        <!-- <thead>
          <tr>
            <th></th>
            <th></th>
            <th></th>
            <th></th>
          </tr>
        </thead> -->
        <tbody>
          <tr v-for="log in $store.state.logs" :key="log.id">
            <td>
              {{ moment(log.action_time * 1000).fromNow() }}
              <small class="ms-1 text-secondary">({{ moment(log.action_time * 1000).format('lll') }})</small>
            </td>
            <td>
              <strong>{{ log.user.username }}</strong>
              {{ log.user.first_name }}
              {{ log.user.last_name }}
            </td>
            <td>
              {{
                {
                  3: 'Удаление',
                  2: 'Изменение',
                  1: 'Добавление',
                }[log.action_flag]
              }}
            </td>
            <td>
              {{
                {
                  1: 'Чат',
                  2: 'Тег',
                  12: 'Лейбл',
                  4: 'Сообщение',
                }[log.content_type_id]
              }}:
              <strong class="me-1">{{ log.object_repr }}</strong>
              <small>({{ log.object_id }})</small>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <Pagination
      v-model="page"
      :records="$store.state.logsCount"
      :per-page="100"
      :options="{
        theme: 'bootstrap3',
        texts: {
          count: 'Показано с {from} до {to} из {count} записей|{count} записей|Одна запись',
          first: 'Первая',
          last: 'Последняя',
        }
      }"
      @paginate="$socket.emit('get_logs', {page})"
    />

  </div>
</template>

<script>
import Pagination from 'v-pagination-3'
import moment from 'moment'
moment.locale('ru')
export default {
  name: 'Logs',
  created: function () {
    this.moment = moment
  },
  components: {
    Pagination,
  },
  data () {
    return {
      page: 1,
    }
  },
  methods: {
    get_logs () {
      this.$socket.emit('get_logs', {
        page: this.page
      })
    },
  },
  async mounted () {
    try {
      if (this.$store.state.user.is_staff) {
        this.$store.commit('mergeStore', {loading: true})
        this.get_logs()
      }
    } catch (e) {
      console.error(e)
      this.$toast.error(`${e.type}: ${e.message}`)
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
